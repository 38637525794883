<template>
  <f7-page name="order_multi" class="" style="background-color: #F6F6F6!important;background-image: none;">
    <!-- Top Navbar -->
    <f7-navbar :sliding="true">
      <!--<f7-nav-left>-->
        <!--<f7-link icon-ios="f7:back" icon-aurora="f7:back" icon-md="material:back" panel-open="left"></f7-link>-->
      <!--</f7-nav-left>-->

      <!--<f7-nav-left>-->
        <!--<f7-link>Left Link</f7-link>-->
      <!--</f7-nav-left>-->
      <f7-nav-left :back-link="true" class="back-icon"></f7-nav-left>
      <f7-nav-title sliding>照片上传-多份</f7-nav-title>
      <!--<f7-nav-right>-->
        <!--<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>-->
      <!--</f7-nav-right>-->
      <!--<f7-nav-title-large sliding>tbimg</f7-nav-title-large>-->
    </f7-navbar>

    <!--订单信息-->
    <div style="margin: 8px 10px;text-align: center;padding:20px 25px;background-color:#fff;line-height: 25px;">
      <div style="display: flex;flex-direction: row;">
        <div class="order-info-left">订单号</div>
        <div class="order-info-right">{{order.tbOrderNo}}</div>
      </div>
      <div style="display: flex;flex-direction: row;">
        <div class="order-info-left">买家昵称</div>
        <div class="order-info-right">{{order.buyerNick}}</div>
      </div>
    </div>


    <!--照片上传-->
    <div class="order-items">
      <!--v-for-->
      <div class="order-item" v-for="item in orderGoodsList">
        <div class="order-row">
          <div class="left" style="flex-grow:1;display: flex;flex-direction: column;align-items: flex-start;" @click="photolist(item)">
            <div class="title" style="font-weight: 700;">
              {{item.tbGoodsName}}
            </div>
            <div class="sub-title" style="display:flex;flex-direction: row;font-size: 70%;margin-top: 5px;">
              <div class="discribe" style="color:#AEB2BD;">{{item.tbPropsName}}</div>
              <!--套数，如果多条则可以-->
              <!--<div class="quantity" style="margin-left: 10px;color:#4987F8;"> x {{item.goodsNum}}</div>-->
            </div>
          </div>
          <div class="right" style="display: flex;flex-direction: row;align-items: center;">
            <img src="/static/img/warn.png" style="height: .9rem;padding-right: 10px" alt="" v-if="item.showTip==1">
            <span class="print-count">{{item.printCount||0}}</span>
            <span class="total-count" v-if="item.goods.maxNum>0">/{{item.goods.maxNum}}</span>
            <img src="/static/img/right.png" style="height: 1rem;padding-left: 20px;" alt="">
          </div>
        </div>
        <div class="order-row">
          <div class="left" style="padding: 15px 0px 0px 0px;flex-grow:1;display: flex;flex-direction: row;align-items: center;">
            <div style="color:#4987F8;padding:1px 5px;" @click="updateQuantity(item,-1)">-</div>
            <div style="border:1px solid #4987F8;color:#4987F8;padding: 1px 10px;line-height: 100%;">{{item.goodsNum}}</div>
            <div style="color:#4987F8;padding:1px 5px;" @click="updateQuantity(item,1)">+</div>
          </div>
          <div class="right-bottom" style="padding: 15px 0px 0px 0px;display: flex;flex-direction: row;">
            <div style="color:#4987F8;border:1px solid #4987F8;padding: 1px 15px;border-radius: 10px;" @click="copyOrderGoods(item)">添加</div>
            <div style="color:#aaa;border:1px solid #aaa;padding: 1px 15px;border-radius: 10px;margin-left: 10px;" @click="removeOrderGoods(item)">删除</div>
          </div>
        </div>
      </div>
    </div>


    <!--样例图-->
    <!--<img src="/static/exp/1.2照片上传.jpg" alt="" width="100%;" style="margin: 20px 0">-->

    <!--底部按钮-->
    <div style="margin-top: 50px;">
      <f7-button fill
                 style="margin: 0 auto;height:45px;line-height: 45px;background-color: #4987F8;position: fixed;bottom: 0;left: 0;right:0;border-radius: 0;"
                 @click="$f7router.back()"
      >
        确定
      </f7-button>
    </div>

  </f7-page>
</template>
<script>
//  '/order/query'
export default {
  props:{
    propOrderGoods:{},
    propOrder:{},
  },
  data() {
    return {
      order:{},
      orderGoodsList:[],
      readonly:false,

      access_token: '',
      showStatusList: [],
      goodsData: {},  //imageDirection: 0, // 照片方向. 无(0)、横向(1)、纵向(2)
      dataList: [],
      winW: window.innerWidth,
      winH: window.innerHeight,
      pixelRatio: 1,
      cH: 200,
      cW: 100,
      uploadNumber: 0,
      isShowUploadNumber: false,
      upLoadNum: '1', // 当前上传总数量.
      curUpLoadNum: 1, // 当前上传的照片.
      isShowUpload: false, // 当前上传的照片
      padding: false //小屏幕...
    }
  },
  mounted() {
    this.$f7ready((f7) => {
      console.log("---",this.$f7route.name,"mounted")

      this.readonly = this.$f7route.query.readonly===1
      this.queryOrder()
      this.querySubOrder()
    });
  },
  activated(){
    console.log("---",this.$f7route.name,"activated")
  },
  beforeRouteLeave(to, from, next) {
    // 设置下一个路由的 meta
//      to.meta.keepAlive = false; // 不缓存，即刷新
    consle.log(to,from,next)
    next();
  },
  methods: {
    queryOrder(){
      console.log("queryOrder")
      let vm=this
      this.$http.post('/order/query',this.$qs.stringify({
        tbOrderNo:this.propOrder.outerOrderNo,
      })).then(res => {
        console.log(res)
        this.order=res.data.data
      }).catch(res => {

      })
    },
    querySubOrder(){
      console.log("querySubOrder")
      this.$http.post('/ordergoods/list',this.$qs.stringify({
        orderId:this.propOrderGoods.orderId,
        goodsId:this.propOrderGoods.goodsId,
      })).then(res => {
        console.log(res)
        this.orderGoodsList=res.data.data
      }).catch(res => {

      })
    },
    updateQuantity(item,offset){
      console.log("updateQuantity",item,offset)
      let newQuantity = item.goodsNum+offset
      if(newQuantity<1){
        this.$f7.toast.create({
          text: '数量最小是1！',
          position: 'center',
          closeTimeout: 2000,
        }).open()
        return
      }
      if(newQuantity>item.goodsNum){
        this.$f7.toast.create({
          text: '已经达到购买的最大数量！',
          position: 'center',
          closeTimeout: 2000,
        }).open()
        return
      }
      this.$http.post('/ordergoods/updateQuantity',this.$qs.stringify({
        orderGoodsId:item.id,
        quantity:newQuantity
      })).then(res => {
        console.log(res)
        if(res.data.status==1){
          this.querySubOrder()
        }else{
          this.$f7.toast.create({
            text: res.data.msg,
            position: 'center',
            closeTimeout: 2000,
          }).open()
        }
      }).catch(res => {

      })
    },
    copyOrderGoods(item){
      this.$http.post('/ordergoods/copy',this.$qs.stringify({
        orderGoodsId:item.id,
      })).then(res => {
        console.log(res)
        if(res.data.status==1){
          this.querySubOrder()
        }else{
          this.$f7.toast.create({
            text: res.data.msg,
            position: 'center',
            closeTimeout: 2000,
          }).open()
        }
      }).catch(res => {

      })
    },
    removeOrderGoods(item){
      this.$http.post('/ordergoods/remove',this.$qs.stringify({
        orderGoodsId:item.id,
      })).then(res => {
        console.log(res)
        if(res.data.status==1){
          this.querySubOrder()
        }else{
          this.$f7.toast.create({
            text: res.data.msg,
            position: 'center',
            closeTimeout: 2000,
          }).open()
        }
      }).catch(res => {

      })
    },
    photolist(orderGoods){
      console.log("orderGoods:",orderGoods)
      this.$f7router.navigate('/photolist/',{
        props:{
          orderGoods:orderGoods,
          callback:this.querySubOrder
        }
      })
    },
  }
}
</script>
<style scoped>
  input::-webkit-input-placeholder { /* WebKit browsers 适配谷歌 */
    color: #C1CAD3;
  }
  .back-icon a{
    color:#151515;
  }
  .order-info-left{
    font-size: .8rem;
  }
  .order-info-right{
    margin-left: 20px;
    font-size: .6rem;
    color:#9EA5B0;
  }
  .order-items{
    background-color: #fff;
    padding: 0 0 0 25px;
  }
  .order-item{
    display: flex;
    flex-direction: column;
    padding: 15px 20px 15px 0;
  }
  .order-row{
    display: flex;
    flex-direction: row;
  }
  .order-item:not(:first-child){
    border-top: 1px solid #ccc;
  }
  .right{
    font-size: .8rem;
  }
</style>