<template>
<f7-app :params="f7params" >


  <!--<router-view url="/"></router-view>-->
  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>
  <!--<f7-view main class="safe-areas" url="/order/123/"></f7-view>-->
  <!--<f7-view main class="safe-areas" url="/photolist/123/"></f7-view>-->
  <!--<f7-view main class="safe-areas" url="/photocrop/123/"></f7-view>-->
</f7-app>
</template>
<script>

  import routes from '../js/routes.js';

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: '亮丽冲印', // App name
          theme: 'auto', // Automatic theme detection


          // App routes
          routes: routes,
          view:{
            stackPages:true,// 路由跳转，多级页面缓存
            reloadDetail:true,
//            pushState:true,// 开启 url 根据路由 hash 变化
//            allowDuplicateUrls:true,//是否允许相同url的新加载的页面作为当前的活跃页面。
          },
          dialog:{
            title:'提示',
            buttonOk:'确定',
            buttonCancel:'取消',
          }
        },

        // Login screen data
        username: '',
        password: '',
      }
    },
    methods: {
      alertLoginData() {
        this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password, () => {
//          this.$f7.loginScreen.close();
        });

      }
    },
    mounted() {
      this.$f7ready((f7) => {
        // Call F7 APIs here
        console.log("app:mounted")
//        this.$f7.dialog.preloader();
        console.log('$app',this.$app)
      });
    },
  }
</script>
<style>
  .navbar-bg{
    background-color: #fff!important;
  }
</style>