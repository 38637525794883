<template>
  <f7-page name="distribution" class="" style="background-color: #F6F6F6;">
    <!-- Top Navbar -->
    <f7-navbar :sliding="true">
      <!--<f7-nav-left>-->
        <!--<f7-link icon-ios="f7:back" icon-aurora="f7:back" icon-md="material:back" panel-open="left"></f7-link>-->
      <!--</f7-nav-left>-->

      <!--<f7-nav-left>-->
        <!--<f7-link>Left Link</f7-link>-->
      <!--</f7-nav-left>-->
      <f7-nav-left :back-link="true" class="back-icon"></f7-nav-left>
      <f7-nav-title sliding>选择物流</f7-nav-title>
      <!--<f7-nav-right>-->
        <!--<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>-->
      <!--</f7-nav-right>-->
      <!--<f7-nav-title-large sliding>tbimg</f7-nav-title-large>-->
    </f7-navbar>

    <iframe style=" width: 100%; height: 100%; border: none; min-height: 500px;position: fixed;"
            src=""
            name="pay-frame"></iframe>
    <span v-html="aliform"></span>

    <!--订单信息-->
    <div style="margin: 8px 10px;text-align: center;padding:20px 25px;background-color:#fff;line-height: 25px;">
      <div style="display: flex;flex-direction: row;">
        <div class="order-info-left">订单号</div>
        <div class="order-info-right">{{order.outerOrderNo}}</div>
      </div>
      <div style="display: flex;flex-direction: row;">
        <div class="order-info-left">买家昵称</div>
        <div class="order-info-right">{{order.buyerNick}}</div>
      </div>
    </div>

    <!--地址信息-->
    <div style="margin: 8px 10px;text-align: center;padding:20px 25px;background-color:#fff;display: flex;flex-direction: row;align-items: center;">
      <div style="padding-right: 30px;">
        <img src="static/img/addr.png" alt="" style="width:1rem;">
      </div>
      <div style="flex-flow: 1;text-align: left;">
        <div class="addr-name">{{order.purchaser}} {{order.purchaserPhone}}</div>
        <div class="addr-addr">{{order.province}}{{order.city}}{{order.district}}{{order.address}}</div>
      </div>
    </div>


    <!--地址选择-->
    <f7-block class="dist-items">
      <label class="radio dist-item">
        <f7-radio
                name="distribution-radio"
                :value="1"
                :checked="true"></f7-radio>

        <div style="flex-flow: 1;text-align: left;line-height: 20px;padding-left:20px;">
          <div class="addr-name">普通快递
            <!--<template>（默认）</template>-->
          </div>
          <div class="addr-addr">根据发货情况自动选择</div>
          <div class="addr-addr">中通、圆通、韵达等物流公司</div>
        </div>
      </label>
      <!--<label class="radio dist-item" v-for="item in distributions">-->
        <!--<f7-radio-->
        <!--name="distribution-radio"-->
        <!--:value="item.id"-->
        <!--:checked="checkedDistribution.id === item.id"-->
        <!--@change="checkDistribution(item)"></f7-radio>-->

        <!--<div style="flex-flow: 1;text-align: left;line-height: 20px;padding-left:20px;">-->
          <!--<div class="addr-name">{{item.logisticsName}}-->
            <!--<template v-if="item.logisticsType=='COMMON'">（默认）</template>-->
            <!--<template v-else-if="item.userPrice>normalPrice">（需补{{item.userPrice-normalPrice}}元差价）</template>-->
          <!--</div>-->
          <!--<div class="addr-addr">根据发货情况自动选择</div>-->
          <!--<div class="addr-addr">中通、圆通、韵达等物流公司</div>-->
        <!--</div>-->
      <!--</label>-->
      <!--<div class="dist-item" v-for="item in distributions">-->
        <!--<div style="padding-right: 30px;">-->
          <!--&lt;!&ndash;<img src="static/img/select-no.png" alt="" style="width:1rem;">&ndash;&gt;-->
          <!--<f7-radio-->
                  <!--name="distribution-radio"-->
                  <!--:value="item.id"-->
                  <!--:checked="checkedDistributionId === item.id"-->
                  <!--@change="checkedDistributionId = item.id"-->
          <!--&gt;</f7-radio>-->
        <!--</div>-->
        <!--<div style="flex-flow: 1;text-align: left;line-height: 20px;">-->
          <!--<div class="addr-name">{{item.logisticsName}}-->
            <!--<template v-if="item.logisticsType=='COMMON'">（默认）</template>-->
            <!--<template v-else-if="item.userPrice>normalPrice">（需补{{item.userPrice-normalPrice}}元差价）</template>-->
          <!--</div>-->
          <!--<div class="addr-addr">根据发货情况自动选择</div>-->
          <!--<div class="addr-addr">中通、圆通、韵达等物流公司</div>-->
        <!--</div>-->
      <!--</div>-->
    </f7-block>


    <!--样例图-->
    <!--<img src="static/exp/1.8-选择物流.jpg" alt="" width="100%;" style="margin: 20px 0">-->

    <!--底部按钮-->
    <div style="margin-top: 50px;">
      <f7-button fill style="margin: 0 auto;height:45px;line-height: 45px;background-color: #4987F8;position: fixed;bottom: 0;left: 0;right:0;border-radius: 0;" @click="submit">
        提交
      </f7-button>
    </div>

  </f7-page>
</template>
<script>
  //  '/portal/api/mall/order/query'
  export default {
    props:{
      order:{},
      callback: {
        type: Function,
        default() {
          return function () {
            console.log("no callback")
          }
        }
      }
    },
    data() {
      return {
//        order:{},
        aliform:'',
        distributions:[],
        normalPrice:0,
        checkedDistribution:{},
      }
    },
    mounted() {
      this.$f7ready((f7) => {
        console.log("---",this.$f7route.name,"mounted")
//        this.order = this.$vnode.data.props.order
        this.getDistributions()
      });
    },
    methods: {
      getDistributions () {
        this.$http.post('/order/logistics', this.$qs.stringify({
          id: this.order.id
        })).then(res => {
//          console.log("----", res)
          if(res.data.status==1){
            this.distributions = res.data.data.logistics
            this.calcNormalPrice()
          }else{

          }
        }).catch(res => {

        })

//        APP.http.request(`/cart/getCartImages`, 'post', { cartId: this.data.goodsData.cartId }).then(res => {
//          swan.hideLoading();
//          if (res.status == 1) {
//            // this.setData({ dataList: res.data })
//            this.setData({ dataList: res.data.map(e => ({ ...e, imgStyle: this.setImgStyle(e), style: this.setCatStyle(e), computeH: this.setComputeH(e), isHeng: this.getImageDData(e) })) })
//          } else {
//            swan.showToast({ title: '请稍后重试', icon: 'none', duration: 2000, mask: true });
//          }
//          this.setImageUploadNum();
//        });
      },
      calcNormalPrice(){
        this.distributions.forEach(d=>{
//          console.log(d)
          if(d.logisticsType=="COMMON"){
            this.normalPrice = d.userPrice
            this.checkedDistribution=d
          }
        })
      },
      checkDistribution(item){
        this.checkedDistribution=item
//        console.log(this.checkedDistribution.id,this.checkedDistribution.logisticsName)
      },
      submit(){
        let vm=this
//        let price = this.checkedDistribution.userPrice-this.normalPrice
//        console.log("支付:"+price)

//        if(price>0){
//          this.$http.post('/alipay/h5pay', this.$qs.stringify({
//            id: this.order.id,
//          })).then(res => {
//            console.log("----", res)
//            this.aliform = res.data.data
//            console.log(this.aliform)
//            setTimeout(function(){
//              document.forms[0].submit()
//            },100)
//            let itv = setInterval(function(){
//              let form = $("[name='punchout_form']")
//              if(form.length>0){
//                form.attr("target","pay-frame")
//                form.submit()
//                window.clearInterval(itv)
//              }
//            },1000)
//
//
////            const div = document.createElement('div');
//////            div.id = 'alipay';
////            div.innerHTML = this.aliform;
////            document.body.appendChild(div);
//
////          if(res.data.status==1){
////            this.distributions = res.data.data.logistics
////            this.calcNormalPrice()
////          }else{
////
////          }
//          }).catch(res => {
//
//          })
//        }else{
          this.$http.post('/order/submit', this.$qs.stringify({
            id: this.order.id,
            expressCode:'COMMON',//this.checkedDistribution.logisticsType
          })).then(res => {
            console.log("----", res)
            if(res.data.status==1){
              vm.$f7.dialog.alert('订单提交成功','成功',function(){

//                vm.$f7router.navigate('/order/?readonly=1',{
//                  props: {
//                    propOrder: this.order,
//                  }
//                })
                vm.$f7router.back()
                if(vm.callback){
                  vm.callback()
                }
              })
            }else{
              vm.$f7.dialog.alert(res.data.msg,'订单提交失败')
            }
          }).catch(res => {

          })
//        }


//        this.$f7router.navigate('/order/?readonly=1&outerOrderNo='+this.order.outerOrderNo)
      },
    }
  }
</script>
<style>
  input::-webkit-input-placeholder { /* WebKit browsers 适配谷歌 */
    color: #C1CAD3;
  }
  .back-icon a{
    color:#151515;
  }
  .addr-name{
    font-size: .8rem;
  }
  .addr-addr{
    font-size: .6rem;
    color:#9EA5B0;
  }
  .dist-items{
    margin: 0;
    /*padding:20px 35px;*/
    background-color:#fff;
    /*display: flex;*/
    /*flex-direction: column;*/
    align-items: center;
  }
  .dist-item{
    display: flex;
    flex-direction: row;
    padding: 35px 20px 15px 45px;
    align-items: center;
  }
  .dist-item:not(:first-child){
    border-top: 1px solid #FAFAFA;
  }
</style>