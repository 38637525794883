<template>
  <f7-page name="photolist" class="" style="background-color: #F6F6F6;overflow-x: hidden;">
    <!-- Top Navbar -->
    <f7-navbar :sliding="true">
      <!--<f7-nav-left>-->
      <!--<f7-link icon-ios="f7:back" icon-aurora="f7:back" icon-md="material:back" panel-open="left"></f7-link>-->
      <!--</f7-nav-left>-->

      <!--<f7-nav-left>-->
      <!--<f7-link>Left Link</f7-link>-->
      <!--</f7-nav-left>-->
      <f7-nav-left :back-link="true" class="back-icon" @back-click="this.callback"></f7-nav-left>
      <f7-nav-title sliding>照片上传/编辑</f7-nav-title>
      <!--<f7-nav-right>-->
      <!--<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>-->
      <!--</f7-nav-right>-->
      <!--<f7-nav-title-large sliding>tbimg</f7-nav-title-large>-->
    </f7-navbar>

    <div style="background-color: #fff;text-align: center;padding: 10px;position: fixed;left: 0;right:0px;z-index:999;">
      <div style="font-size: .8rem;font-weight: bold;">商品名称：{{propOrderGoods.tbGoodsName}}</div>
      <div style="font-size: .8rem;color: rgb(73, 135, 248);">
        <div>显示区域即为冲印区域，请仔细核对</div>
        <div>如需调整请点击图片</div>
      </div>
    </div>

    <!--照片列表-->
    <div class="photo-list " id="photolist1">
      <div class="photo-item" v-for="(item,idx) in imageList" v-if="imageList.length>0">
        <div class="photo-item-img" :style="(item.pixelFlag==1 || item.faceFlag==1)?'border:none;':''">
          <!--图片框架的高度，由框架宽度和显示宽高计算得出-->
          <div class="photo-item-img-inner-relative"
               :style="'width:'+item.frameWidth+'px;height:'+item.frameHeight+'px;'">
            <div class="photo-item-close" @click="delectItem(item)" v-if="!propReadonly">x</div>
            <div class="photo-item-img-inner-absolute lazywrapper" @click="editImg(item)"
                 :style="'width:'+item.frameWidth+'px;height:'+item.frameHeight+'px;'">
              <img class="photo-item-img-img" v-lazy="item.listImageUrl"
                   :style="'width:'+item.imgWidth+'px;left:'+item.imgLeft+'px;top:'+item.imgTop+'px;'" />
            </div>
          </div>
        </div>

        <div class="photo-item-countblock" style="height: 30px;">
          <img class="photo-item-countblock-img" src="static/img/crop/icon_minus.png" @click="minusCount(item)"/>
          <span class="photo-item-countblock-input">{{item.quantity || 1}}</span>
          <img class="photo-item-countblock-img" src="static/img/crop/icon_add.png" @click="addCount(item)"/>
        </div>

        <div :class="'item_no_img '+(item.isHeng ? 'is_h_image' : '')" v-if="false || item.pixelFlag == 1">
          <img style="display: inline-block; margin: 0 auto;" class="item_no_img_icon"
               src="static/img/crop/icon_yellow.png"/>
          <div>照片不够清晰</div>
          <div class="item_no_img_btn img_btn_left" @click="ignorePixelShort(item)">没关系</div>
          <div class="item_no_img_btn img_btn_right" @click="delectItem(item)">删除</div>
        </div>
        <div :class="'item_no_img '+(item.isHeng ? 'is_h_image' : '')" v-else-if="false || item.faceFlag == 1">
          <img style="display: inline-block; margin: 0 auto;" class="item_no_img_icon"
               src="static/img/crop/icon_red.png"/>
          <div></div>
          检测到人脸被裁
          <div class="item_no_img_btn img_btn_left" @click="processFaceShort(item,2)">没关系</div>
          <div class="item_no_img_btn img_btn_right" @click="processFaceShort(item,3)">调整</div>
        </div>
      </div>

      <div class="photo-item" ref="photoframeWrapper">
        <div class="photo-item-img" ref="photoframe" @click="chooseImg">+</div>
        <div class="photo-item-countblock">
        </div>
      </div>
      <!-- <div s-for="item in showStatusList" class="content" style="width: 100vw; color: red;">
          {{item}}
      </div> -->
    </div>


    <!--样例图-->
    <!--<img src="static/exp/photos3.png" alt="" width="100%;" style="margin: 20px 0">-->
    <!--<img src="static/exp/photos1.png" alt="" width="100%;" style="margin: 20px 0">-->
    <!--<img src="static/exp/photos2.png" alt="" width="100%;" style="margin: 20px 0">-->

    <!--底部按钮-->
    <div style="margin: 0px;height:45px;background-color: #fff;position: fixed;bottom: 0;left: 0;right:0;
      display: flex;flex-direction: row;align-items: center;z-index: 99;
    ">
      <div style="flex-grow: 1;text-align: center;">
        <div style="font-size: .95rem;font-weight: bold;" @click="chooseImg" v-if="!propReadonly">
          添加照片
        </div>
        <div style="font-size: .7rem;color:#777;">
          已上传{{printCount}}张
          <template v-if="lackCount>0 && !propReadonly">，还需上传{{lackCount}}张</template>
        </div>
      </div>
      <f7-button fill
                 style="height:45px;line-height: 45px;width:120px;border-radius: 0;background-color: #4987F8;"
                 @click="finish"
      >
        确定
      </f7-button>
      <input ref="upload_input" type="file" multiple="multiple" accept="image/*" @change="addImg"
             style="display: none;"/>
    </div>

  </f7-page>
</template>
<script>
  export default {
    props: {
      propReadonly:{
        type:Boolean,
        default(){
          return true
        }
      },
      propOrderGoods: {
        type: Object,
        default() {
          return {}//obj是orderGoodsList的其中一个，这里面的imageList是空的
        }
      },
      callback: {
        type: Function,
        default() {
          return function () {
            console.log("no callback")
          }
        }
      }
    },
    data() {
      return {
//        orderItem:{},//obj是orderGoodsList的其中一个，这里面的imageList是空的
        imageList: [],//这个是真正的照片列表信息，到这个界面需要查询出来

        frameMaxWidth: 1,//图片框架实际宽度，从photoframe获取
        frameMaxHeight: 2,//图片框架实际高度，从photoframe获取

        access_token: '',
        showStatusList: [],
        goodsData: {},  //imageDirection: 0, // 照片方向. 无(0)、横向(1)、纵向(2)
        dataList: [],
        winW: window.innerWidth,
        winH: window.innerHeight,
        pixelRatio: 1,
        cH: 200,
        cW: 100,
        uploadNumber: 0,
        isShowUploadNumber: false,
        upLoadNum: '1', // 当前上传总数量.
        curUpLoadNum: 1, // 当前上传的照片.
        isShowUpload: false, // 当前上传的照片
        padding: false, //小屏幕...

        // weixin选图        
        appId:'wx089b04ffb8b8a525',
        timestamp:'1622127390',
        noncestr:'980e2c1a-8b6a-4d98-998f-53d18cb48c03',
        signature:'d6461958afb343275062fb06b4164bacf9024cb8',
        // localIds:[],
      }
    },
    beforeRouteLeave(to, from, next) {
      // 设置下一个路由的 meta
//      to.meta.keepAlive = false; // 不缓存，即刷新
      consle.log(to, from, next)
      next();
    },
    mounted() {
      this.$f7ready((f7) => {
        console.log("---", this.$f7route.name, "mounted")
//        console.log("-query:",this.$f7route.query)
//        console.log(this.$vnode.data.props.orderGoods)
//        this.propOrderGoods = this.$vnode.data.props.orderGoods
        this.getPhotoList()

        this.frameMaxWidth = this.$refs.photoframe.offsetWidth
        this.frameMaxHeight = this.$refs.photoframe.offsetHeight
        console.log("photoframe:", this.frameMaxWidth, this.frameMaxHeight)
        if(this.propReadonly){
          this.$refs.photoframeWrapper.style="display:none;"
        }
        if(this.is_weixn()){
          console.log("是微信1")
          this.wexinInit()
        }else{
          console.log("不是微信1")
        }

//        app.lazy.create("page-content")
//        debugger
      });
    },
    activated() {
      console.log("---", this.$f7route.name, "activated")
    },
    computed: {
      printCount() {
        let count = 0
        this.imageList.forEach(img => {
          count += img.quantity
        })
        return count
      },
      lackCount() {
        if (this.propOrderGoods && this.propOrderGoods.goods) {
          let maxNum = this.propOrderGoods.goods.maxNum || 0
          let lack = this.propOrderGoods.goods.maxNum - this.printCount
          return lack > 0 ? lack : 0
        }
      }
    },
    methods: {
      is_weixn(){
        var ua = navigator.userAgent.toLowerCase();
        console.log("ua:",ua)
        if(ua.match(/MicroMessenger/i)=="micromessenger") {
          return true;
        } else {
          return false;
        }
      },
      wexinInit(){
        
        this.$http.post('/wxticket', this.$qs.stringify({
          url: location.href.split('#')[0],
        })).then(res => {
          console.log('/wxticket:',res)
          if (res.status == 200 && res.data.status == 1) {
            console.log("data:",res.data.data)

            this.timestamp=res.data.data.timestamp
            this.noncestr=res.data.data.noncestr
            this.signature=res.data.data.signature

          }
        }).catch(res => {
          console.log(res)
        }).finally(res=>{
            this.$wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。  
              appId: this.appId, // 必填，公众号的唯一标识  
              timestamp: this.timestamp, // 必填，生成签名的时间戳  
              nonceStr: this.noncestr, // 必填，生成签名的随机串  
              signature: this.signature,// 必填，签名，见附录1  
              jsApiList : [ 'chooseImage', 'previewImage', 'uploadImage', 'downloadImage', 'chooseWXPay', 'getLocalImgData' ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2 
            })
        })
      },
      getPhotoList() {
        console.log("getPhotoList")
        this.$http.post('/ordergoods/getImages', this.$qs.stringify({
          orderGoodsId: this.propOrderGoods.id
        })).then(res => {
//          console.log("----", res)
          if (res.data.status == 1) {
            this.initImageList(res.data.data)
          } else {

          }
        }).catch(res => {

        })
      },
      initImageList(imageList) {
        for (let i = 0; i < imageList.length; i++) {
          let img = imageList[i]
          this.initImage(img)
        }
        this.imageList = imageList
      },
      // 根据返回值计算样式，这个是控制图片显示的关键函数
      initImage(img) {
        /*
        接口返回值说明：
        "width": 1050,//图片冲印宽高
        "height": 1145,//图片冲印宽高
        "paramX": 0,//左右偏移，大于0表示图片向左
        "paramY": 127,//上下偏移，大于0代表图片向上
        "w": 1050,//图片实际宽高
        "h": 1400,//图片实际宽高
        */

        //frameWidth、frameHeight，框架宽度、框架高度



//        debugger
        //框架宽高比。比率大于等于这个，图片宽度等于最大宽度，反之，高度等于最大高度
        if (img.width / img.height >= this.frameMaxWidth / this.frameMaxHeight) {
          img.frameWidth = this.frameMaxWidth
          img.frameHeight = this.frameMaxWidth * img.height / img.width
          img.scale = img.frameWidth/img.width
        } else {
          img.frameHeight = this.frameMaxHeight
          img.frameWidth = this.frameMaxHeight * img.width / img.height
          img.scale = img.frameHeight/img.height
        }
        // width越小，说明图片显示区域越小，图片就要放大
        if(img.rotate==0 || img.rotate==180){
          //横版
          img.imgWidth = img.frameWidth * img.w / img.width
          // width越小，说明图片大，那么实际的left和top需要缩小
//          img.imgLeft = -img.paramX * img.frameWidth / img.width
          img.imgLeft = -img.paramX * img.scale
//          img.imgTop = -img.paramY * img.frameWidth / img.height
          img.imgTop = -img.paramY * img.scale
          // console.log("横版",img)
        }else{
          //竖版
          img.imgWidth = img.frameWidth * img.h / img.width
          // width越小，说明图片大，那么实际的left和top需要缩小
//          img.imgLeft = -img.paramX * img.frameWidth / img.width
          img.imgLeft = -img.paramX * img.scale
//          img.imgTop = -img.paramY * img.frameWidth / img.height
          img.imgTop = -img.paramY * img.scale
          // console.log("竖版",img)
        }

      },
      /**
       * 选择照片
       */
      chooseImg() {
        if(this.propReadonly){
          return
        }
        let vm=this
        let maxNum = this.propOrderGoods.goods.maxNum
        console.log(this.propOrderGoods,this.printCount,maxNum)
        if(maxNum && this.printCount<maxNum){
          if(!this.is_weixn()){
            this.$refs.upload_input.dispatchEvent(new MouseEvent('click'))
          }else{
            let lackCount = maxNum-this.printCount
            lackCount = lackCount>9 ? 9 : lackCount
            this.$wx.chooseImage({              
              count: lackCount, // 默认9
              sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
              sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
              success:function(res){
                let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                console.log("通过微信选择了图片：",localIds)
                vm.addImgWexin(localIds)
              },
              fail: function (res) {
                this.$f7.dialog.alert("上传失败")
                resolve("chooseImageFail");
              }
            })
          }
        }else{
          this.$f7.dialog.alert('已达到上传照片的最大数量', '提示')
        }
      },
      //获取base64大小
      getImgByteSize(data) {
        let size = 0
        if (data) { // 获取base64图片byte大小
          const equalIndex = data.indexOf('='); // 获取=号下标
          if (equalIndex > 0) {
            const str = data.substring(0, equalIndex); // 去除=号
            const strLength = str.length;
            const fileLength = strLength - (strLength / 8) * 2; // 真实的图片byte大小
            size = Math.floor(fileLength); // 向下取整
          } else {
            const strLength = data.length;
            const fileLength = strLength - (strLength / 8) * 2;
            size = Math.floor(fileLength); // 向下取整
          }
        }
        return (size * 1.657)
      },
      /**
       * 上传照片，非微信
       */
      addImg() {
        // 控制多张上传：https://blog.csdn.net/qq_39264897/article/details/83375206
        var vm = this;
        let inputFiles = null
        if (this.$refs.upload_input) {
          inputFiles = this.$refs.upload_input.files
        } else {
          this.$f7.dialog.alert('上传失败请重试', '提示')
          return
        }
        console.log("inputFiles:", inputFiles)
        if (!inputFiles || inputFiles.length == 0) return

        let successCount = 0
        let errorCount = 0
        let dialog = this.$f7.dialog.progress('上传中...', 0);
        dialog.setText('1/' + inputFiles.length);


//        for(let fileIdx in inputFiles){
        for (let fileIdx = 0; fileIdx < inputFiles.length; fileIdx++) {
          try {
            const inputFile = inputFiles[fileIdx];
            console.log("fileIdx:", fileIdx, inputFile)
            if (inputFile) {
//            if(inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif'){
//              alert('不是有效的图片文件！');
//              return;
//            }


              let param = new FormData()
              param.append("file", inputFile)
              param.append("orderGoodsId", this.propOrderGoods.id)

              this.$http.post('/ordergoods/upload', param, {
                headers: {"Content-Type": "multipart/form-data"},
                //添加上传进度监听事件
//                onUploadProgress: e => {
//                  var completeProgress = ((e.loaded / e.total * 100) | 0) + "%";
//                  console.log("上传进度：",completeProgress)
//                }
              }).then(res => {
                console.log("----", res)

                if (res.data.status == 1) {
                  successCount++
                } else {
                  errorCount++
                  console.log(res.data.msg)
                }
                let allCount = successCount + errorCount
                dialog.setProgress(allCount / inputFiles.length * 100)
                dialog.setText(allCount + '/' + inputFiles.length);
                if (allCount == inputFiles.length) {
                  dialog.close();
                  this.getPhotoList()
                  let alertStr = "成功:" + successCount + "，失败:" + errorCount
                  vm.$f7.dialog.alert(alertStr, '上传完成')

                  //文件选择组件清0，避免选择同一个文件无法上传
                  vm.$refs.upload_input.value = null
                }
              }).catch(res => {
                console.log("catch:", res)
                errorCount++
              })
            }
          } catch (e) {
            console.log(e)
          }
        }
      },
      /**
       * 上传照片，微信
       */
      addImgWexin(localIds) {
        // console.log(localIds)
        // 控制多张上传：https://blog.csdn.net/qq_39264897/article/details/83375206
        var vm = this;
        if (!localIds || localIds.length == 0) return

        let localIdsLength=localIds.length
        let successCount = 0
        let errorCount = 0
        let dialog = this.$f7.dialog.progress('上传中...', 0);
        dialog.setText('1/' + localIdsLength);


        function processNext(){
          if (successCount + errorCount == localIdsLength) {
            dialog.close();
            vm.getPhotoList()
            let alertStr = "成功:" + successCount + "，失败:" + errorCount
            vm.$f7.dialog.alert(alertStr, '上传完成')
          }else{
            
          }
          let localId = localIds.pop()
          if (localId) {
            console.log('转换开始',localId)
            vm.$wx.getLocalImgData({
                localId: localId.toString(),
                success: function (res) {
                  console.log("转换结束",localId)
                  console.log('getLocalImgData res:',res)
                  const localData = res.localData;
                  let imageBase64 = '';
                  if (localData.indexOf('data:image') == 0) {
                      //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
                      imageBase64 = localData;
                  } else {
                      //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
                      //此时一个正常的base64图片路径就完美生成赋值到img的src中了
                      imageBase64 = 'data:image/jpeg;base64,' + localData.replace(/\n/g, '');
                  }
                  // imageBase64=imageBase64.split(',')[1]
                  console.log(imageBase64)

                  let param = new FormData()
                  param.append("orderGoodsId", vm.propOrderGoods.id)
                  param.append("imageBase64",imageBase64)
                  console.log('param:',param)

                  let uploadurl = '/ordergoods/upload/'+vm.propOrderGoods.id
                  console.log('uploadurl:',uploadurl)


                  vm.$http.post(uploadurl,imageBase64,{
                    headers: {"Content-Type": "text/plain"},
                  })
                  // vm.$http.post(uploadurl,param,{
                  //   headers: {"Content-Type": "application/json"},
                  // })
                  .then(res => {
                    console.log("----", res)

                    if (res.data.status == 1) {
                      successCount++
                    } else {
                      errorCount++
                      console.log(res.data.msg)
                    }
                    let allCount = successCount + errorCount
                    dialog.setProgress(allCount / localIdsLength * 100)
                    dialog.setText(allCount + '/' + localIdsLength);
                    processNext()
                  }).catch(res => {
                    console.log("catch:", res)
                    errorCount++
                    processNext()
                  })

                }
            });
          }
        }
        processNext()
      },
      /**
       * 删除照片:ok
       */
      delectItem(item) {
        console.log(item)
        this.$f7.dialog.confirm('确定删除？', '', (e => {
          //确定
          this.$http.post('/ordergoods/image/removePhoto', this.$qs.stringify({
            id: item.id
          })).then(res => {
            console.log("----", res)
            if (res.data.status == 1) {
              for (let i = 0; i < this.imageList.length; i++) {
                if (this.imageList[i].id == item.id) {
                  this.imageList.splice(i, 1)
                  break
                }
              }
            } else {

            }
          }).catch(res => {

          })
        }), (e => {
          //取消
        }))

      },
      /**
       * 减少数量
       * @param item
       */
      minusCount(item) {
        if(this.propReadonly){
          return
        }
        let newQuantity = (item.quantity || 1) - 1
        if (newQuantity <= 0) {
          return
        } else {
          this.setImgQuantity(item, newQuantity)
        }
      },
      /**
       * 增加数量
       * @param item
       */
      addCount(item) {
        if(this.propReadonly){
          return
        }
        let maxNum = this.propOrderGoods.goods.maxNum
        if(!maxNum || this.printCount<maxNum){
          let newQuantity = (item.quantity || 1) + 1
          this.setImgQuantity(item, newQuantity)
        }else{
          this.$f7.dialog.alert('已达到上传照片的最大数量', '提示')
        }
      },
      /**
       * 修改照片数量
       * @param id
       * @param quantity
       */
      setImgQuantity(item, newQuantity) {
        item.quantity = newQuantity
        this.$http.post('/ordergoods/image/updatePhotoQuantity', this.$qs.stringify({
          id: item.id,
          quantity: newQuantity,
        })).then(res => {
          console.log("----", res)
//          if(res.data.status==1){
//            item.quantity = newQuantity
//          }else{
//
//          }
        }).catch(res => {

        })
      },
      /**
       * 忽略照片太小
       * @param item
       */
      ignorePixelShort(item) {
        this.$http.post('/ordergoods/image/ignorePixelShort', this.$qs.stringify({
          id: item.id,
        })).then(res => {
          console.log("----", res)
          if (res.data.status == 1) {
            item.pixelFlag = 2
          } else {

          }
        }).catch(res => {

        })
      },
      /**
       * 忽略人脸裁切
       * @param faceFlag
       */
      processFaceShort(item, faceFlag) {
        // flag 处理类型，2：忽略，3：用户已处理
        this.$http.post('/ordergoods/image/processFaceShort', this.$qs.stringify({
          id: item.id,
          flag: faceFlag,
        })).then(res => {
          console.log("----", res)
          if (res.data.status == 1) {
            item.faceFlag = faceFlag
            if (flag == 3) {
              this.editImg(item)
            }
          } else {

          }
        }).catch(res => {

        })
      },
      /**
       * 跳转到裁切界面
       * @param item
       */
      editImg(item) {

//        if(this.propReadonly){
//          return
//        }
        this.$f7router.navigate('/photocrop/', {
          props: {
            propImage: item,
            propImageList: this.imageList,
            propOrderGoodsId:this.propOrderGoods.id,
            propReadonly:this.propReadonly,
            callback: this.getPhotoList
          }
        })
      },
      finish() {
        let maxNum = this.propOrderGoods.goods.maxNum
        console.log(this.propOrderGoods,this.printCount,maxNum)
        if(maxNum){
          if(this.printCount==maxNum || this.propReadonly){
            this.callback()
            this.$f7router.back()
          // }else if(this.printCount*2<maxNum){
          //   this.$f7.dialog.alert('还需要上传'+this.lackCount+'张照片', '提示')
          //   return
          }else if(this.printCount<maxNum){
            this.$f7.dialog.alert('还需要上传'+this.lackCount+'张照片', '提示')
            return
            
            // this.$f7.dialog.buttonOk="继续上传"
            // this.$f7.dialog.buttonCancel="确定提交"
            
            this.$f7.dialog
                .create({
                  title:'提示',
                  text: '上传数量不足，还需要上传'+this.lackCount+'张照片。确定要提交吗？',
                  buttons: [
                    {
                      text: '确定提交',
                      color:'gray',
                      onClick: () => {
                        console.log("确定提交")
                        this.callback()
                        this.$f7router.back()
                      }
                    },
                    {
                      text: '继续上传',
                      onClick: () => {
                        console.log("继续上传")
                      }
                    }
                  ]
                })
                .open();

            // this.$f7.dialog.confirm('上传数量不足，还需要上传'+this.lackCount+'张照片。确定要提交吗？', '提示', (e => {
            //   //确定
            //   console.log("确定提交")
            // }), (e => {
            //   //取消
            //   console.log("继续上传")
            //   // this.callback()
            //   // this.$f7router.back()
            // }))
          }else{
            this.$f7.dialog.alert('超过了上传照片的最大数量，请减少照片后再提交', '提示')
          }
        }

      },
    },
  }
</script>
<style scoped>
  .page-content {
    overflow-x: hidden;
  }

  input::-webkit-input-placeholder { /* WebKit browsers 适配谷歌 */
    color: #C1CAD3;
  }

  .back-icon a {
    color: #151515;
  }

  /*列表容器*/
  .photo-list {
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*justify-content: space-between;*/
    /*overflow-y: auto;*/
    margin: 86px 0 100px 0;
    padding: 15px;

    display: grid;
    /*grid-template: repeat(2, 1fr) / repeat(2, 1fr);*/
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  /*for的元素*/
  .photo-item {
    /*width:50%;*/
    height: 260px;
    margin-bottom: 0px;

    display: flex;
    align-items: center;
    flex-direction: column;

    /*relative的作用是让子元素的absolute是基于父元素的*/
    position: relative;
    border: 1px dashed #ccc;
  }

  /*图片内容*/
  .photo-item-img {
    width: 100%;
    /*height: 100%;*/
    margin: 0px;
    /*padding: 2px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    /*border: 1px solid #ccc;*/
    /*background: #fff;*/
    cursor: pointer;
    text-align: center;
    font-size: 80px;
    font-weight: lighter;
    color: #666;
    /*overflow: hidden;*/

    /*position: relative;*/
  }

  /*实际冲印大小，包含关闭按钮*/
  .photo-item-img-inner-relative {
    position: relative;
    border: 1px solid #999;
    background-color: #fbfbfb;
  }

  /*实际冲印大小*/
  .photo-item-img-inner-absolute {
    position: absolute;
    overflow: hidden;
    width: 100%;
  }

  .photo-item-img-img {
    position: absolute;
  }

  /*计数器*/
  .photo-item-countblock {
    margin-top: 3px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #3385ff;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /*计数器 - 输入框*/
  .photo-item-countblock-input {
    /*display: inline-block;*/
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    width: 30px;
    height: 30px;
    /*margin: 0 20px;*/
    color: #3385ff;
    /*transform: translateY(5px);*/
  }

  /*计数器 - 加/减*/
  .photo-item-countblock-img {
    height: 18px;
    width: 18px;
  }

  /*删除按钮*/
  .photo-item-close {
    width: 20px;
    height: 20px;
    line-height: 18px;

    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 11;

    text-align: center;
    border: 1px solid #777;
    border-radius: 50%;
    color: #777;
    background-color: #fff;
    font-size: 1.1rem;
  }

  .item_no_img {
    background-color: rgba(66, 66, 66, 0.6);
    color: #fff;
    height: 100%;
    /* line-height: 150px; */
    box-sizing: border-box;
    padding-top: 50%;
    width: 100%;
    text-align: center;
    position: absolute;
    /*position: relative;*/
    left: 0;
    top: 0;
  }

  .item_no_img.is_h_image {
    padding-top: 15%;
  }

  .item_no_img.is_h_image .item_no_img_btn {
    bottom: 15px;
  }

  .item_no_img_icon {
    height: 15px;
    width: 15px;
  }

  /*按钮*/
  .item_no_img_btn {
    /* height: 20px;
    line-height: 20px; */
    /* width: 60px; */
    padding: 5px 12px;
    position: absolute;
    bottom: 50px;
    font-size: 10px;

    border-radius: 20px;

    /* border: 1px solid #c9c9c9;
    background-color: #fff; */
    background-color: #3385ff;
    color: #fff;
  }

  /*按钮-left*/
  .img_btn_left {
    left: 10px;
  }

  /*按钮-right*/
  .img_btn_right {
    right: 10px;
  }

  .img_btn_left_small {
    left: 10px;
    /* padding: 5px 10px; */
    font-size: 12px;
  }

  .img_btn_right_small {
    right: 10px;
    /* padding: 2px 10px; */
    font-size: 12px;
  }

  .image_modal {
    height: 100vh;
    width: 100vw;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    background-color: rgba(31, 31, 31, 0.5);
    z-index: 111;
  }

  .upload_image_number {
    width: 80vw;
    margin: 20vh auto 0;
    background-color: #fff;
    text-align: center;
    padding-bottom: 15px;
  }

  .upload_image_number > div.u_i_n_text {
    color: #999;
    font-size: 14px;
  }

  .upload_image_number > div.u_i_n_title {
    color: #333;
    font-size: 18px;
  }

  .u_i_n_image {
    width: 100%;
    height: 100px;
  }

</style>
