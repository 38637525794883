// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';


// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

import {registerEvent,unregisterEvent,hasEvent} from './eventhandle.js';
// console.log(registerEvent)
// console.log(unregisterEvent)
// console.log(hasEvent)
Vue.prototype.registerEvent = registerEvent
Vue.prototype.unregisterEvent = unregisterEvent
Vue.prototype.hasEvent = hasEvent

// 添加axios20200320
import axios from 'axios'
import vueAxios from 'vue-axios'
// axios.defaults.baseURL = 'https://attest.gotomai.com/hsxtmall'


//https://pdd.gotomai.com/prod-api/portal/api/mall/order/query
let host = window.location.host; //主机
if(/^localhost+/.test(host) || /^192.168+/.test(host) || /^test+/.test(host)) {
  //若本地项目调试使用
  axios.defaults.baseURL = 'http://localhost:9015/mallapi';
  axios.defaults.baseURL='http://pddtesting.axurebuy.com/mallapi/portal/api/mall'
  axios.defaults.baseURL='http://pddtesting.axurebuy.com/mallapi'
  // axios.defaults.baseURL='https://attest.gotomai.com/hsxtmall'
  // axios.defaults.baseURL='http://pdd.gotomai.com:1024/mallapi/portal/api/mall'
  axios.defaults.baseURL='https://pdd.gotomai.com/mallapi'
  axios.defaults.baseURL='https://pddsc.gotomai.com'
  axios.defaults.baseURL='https://pddsc.gotomai.com/mallapi'
} else {
  // axios.defaults.baseURL='/prod-api/portal/api/mall'
  axios.defaults.baseURL = '/mallapi'
  // axios.defaults.baseURL = '/mallapi/portal/api/mall'
}

// axios.defaults.crossDomain = true;
// axios.defaults.withCredentials = true;  //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
// axios.defaults.headers.common['storeId'] = '100000';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// const AxiosInstance = Axios.created({
//   'baseURL':'http://attest.gotomai.com/hsxtmall',
//   'Content-Type':'application/x-www-form-urlencoded',
// })
Vue.use(vueAxios,axios)

// 引入weixin的js-sdk
import wx from 'weixin-js-sdk';
Vue.use(wx)
Vue.prototype.$wx=wx


import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

// 全局引入qs
import qs from 'qs'
Vue.prototype.$qs=qs


// 引入 vue-cropper做图片裁切
// import VueCropper from 'vue-cropper'
// import VueCropper from '../components/cropper/vue-cropper.vue';
// Vue.use(VueCropper)


// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
});