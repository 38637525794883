
import HomePage from '../pages/home.vue';
// import AboutPage from '../pages/about.vue';
// import FormPage from '../pages/form.vue';

import OrderPage from '../pages/order.vue';
import OrderMultiPage from '../pages/order_multi.vue';
import DistributionPage from '../pages/distribution.vue';
import PhotoListPage from '../pages/photolist.vue';
import PhotoCropPage from '../pages/photocrop.vue';


// import DynamicRoutePage from '../pages/dynamic-route.vue';
// import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    name:'1home',
    component: HomePage,
    //keepAlive: true,
  },
  // 订单
  {
    path: '/order/',
    name:'2.1 order',
    component: OrderPage,
    // keepAlive: true,
  },
  // 订单-多份
  {
    path: '/ordermulti/',
    name:'2.2 order_multi',
    component: OrderMultiPage,
    // keepAlive: true,
  },
  // 照片列表
  {
    path: '/photolist/',
    name:'3photolist',
    component: PhotoListPage,
    // keepAlive: true,
  },
  // 照片裁切
  {
    path: '/photocrop/',
    name:'4photocrop',
    component: PhotoCropPage,
    // keepAlive: true,
  },
    // 物流
  {
    path: '/distribution/',
    name:'5distribution',
    component: DistributionPage,
    // keepAlive: true,
  },


  // {
  //   path: '/about/',
  //   component: AboutPage,
  // },
  // {
  //   path: '/form/',
  //   component: FormPage,
  // },
  // {
  //   path: '/dynamic-route/blog/:blogId/post/:postId/',
  //   component: DynamicRoutePage,
  // },
  // {
  //   path: '/request-and-load/user/:userId/',
  //   async: function (routeTo, routeFrom, resolve, reject) {
  //     // Router instance
  //     var router = this;
  //
  //     // App instance
  //     var app = router.app;
  //
  //     // Show Preloader
  //     app.preloader.show();
  //
  //     // User ID from request
  //     var userId = routeTo.params.userId;
  //
  //     // Simulate Ajax Request
  //     setTimeout(function () {
  //       // We got user data from request
  //       var user = {
  //         firstName: 'Vladimir',
  //         lastName: 'Kharlampidi',
  //         about: 'Hello, i am creator of Framework7! Hope you like it!',
  //         links: [
  //           {
  //             title: 'Framework7 Website',
  //             url: 'http://framework7.io',
  //           },
  //           {
  //             title: 'Framework7 Forum',
  //             url: 'http://forum.framework7.io',
  //           },
  //         ]
  //       };
  //       // Hide Preloader
  //       app.preloader.hide();
  //
  //       // Resolve route to load page
  //       resolve(
  //         {
  //           component: RequestAndLoad,
  //         },
  //         {
  //           context: {
  //             user: user,
  //           }
  //         }
  //       );
  //     }, 1000);
  //   },
  // },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
