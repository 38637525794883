<template>
  <f7-page name="home" style="background: url('static/img/home-background.png') no-repeat;
    background-color: #F6F6F6;
    background-size:100% 100%;
    background-attachment:fixed;">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <!--<f7-nav-left>-->
      <!--<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>-->
      <!--</f7-nav-left>-->
      <f7-nav-title sliding>影像编辑平台</f7-nav-title>
      <!--<f7-nav-right>-->
      <!--<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>-->
      <!--</f7-nav-right>-->
      <!--<f7-nav-title-large sliding>tbimg</f7-nav-title-large>-->
    </f7-navbar>
    <div style="margin: 20px;text-align: center;border-radius: 10px;padding:20px 0;background-color:#fff;">
      <div class="login-top" style="padding: 5px 0 25px 0;min-height:60px;">
        <img :src="logoImg" alt="" width="60%" v-if="logoImg">
      </div>
      <div class="login-bottom" style="border-top: 1px solid #F9F9F9;text-align: center;padding:18px 30px 0px 30px;">
        <form action="javascript:return true">
        <input ref="orderInput" type="search"
               style="background-color: #f2f2f2;width:100%;margin:0 auto;line-height: 32px;padding-left:30px;background: url('static/icons/search.png') no-repeat #f2f2f2; background-size: 13px;background-position:10px;"
               placeholder="请输入订单号" v-model="outerOrderNo" @keyup.enter="queryOrder">
        </form>
      </div>
    </div>
    <div style="margin: 20px;">
      <f7-button fill style="max-width: 60%;margin: 0 auto;height:38px;line-height: 36px;background-color: #3285FF;"
                 @click="queryOrder">
        查 询
      </f7-button>
      <!-- <p style="text-align:center">测试数据：</p>
      <p v-for="orderno in ['210528-004991294162544']" style="text-align:center;">
        <a href="javascript:;" @click="outerOrderNo=orderno;queryOrder()">{{orderno}}</a>
      </p> -->
      <!-- <div>
        <a href="javascript:;" @click="chooseImg">微信上传测试，可以选多张，如果能显示第一张就说明成功</a>
      </div>
      <div>
        <img :src="testSrc" alt="" style="width:100%">
      </div> -->
      <!-- <div>
        <p>appId：{{appId}}</p>
        <p>timestamp：{{timestamp}}</p>
        <p>noncestr：{{noncestr}}</p>
        <p>signature：{{signature}}</p>
        <p>localId: {{localId}}</p>
        <p>localData: {{localData}}</p>
      </div> -->
    </div>

  </f7-page>
</template>
<script>
  //  '/order/query'

  import wx from 'weixin-js-sdk'//微信js
  export default {
    props:{
      propOrder:{}
    },
    data() {
      return {
        outerOrderNo: '',
        access_token: '',
        showStatusList: [],
        goodsData: {},  //imageDirection: 0, // 照片方向. 无(0)、横向(1)、纵向(2)
        dataList: [],
        winW: window.innerWidth,
        winH: window.innerHeight,
        pixelRatio: 1,
        cH: 200,
        cW: 100,
        uploadNumber: 0,
        isShowUploadNumber: false,
        upLoadNum: '1', // 当前上传总数量.
        curUpLoadNum: 1, // 当前上传的照片.
        isShowUpload: false, // 当前上传的照片
        padding: false, //小屏幕...
        testSrc:'',
        appId:'wx089b04ffb8b8a525',
        timestamp:'1622127390',
        noncestr:'980e2c1a-8b6a-4d98-998f-53d18cb48c03',
        signature:'d6461958afb343275062fb06b4164bacf9024cb8',
        localData:'',
        localId:'',

        storeId:'',
        logoImg:'',
      }
    },
    mounted() {
//      let _this = this
      this.$f7ready((f7) => {
        console.log("---", this.$f7route.name, "mounted")
        this.setStoreId()
        this.initLogo()
//        console.log(this.registerEvent)
//        console.log(registerEvent)
//        console.log(this)
//        debugger
//        this.registerEvent("backbutton", this.clickBackBtn)
//        this.registerEvent("popstate", this.clickBackBtn)
//        debugger
//        if(this.propOrder && this.propOrder.outerOrderNo){
//          this.outerOrderNo = this.propOrder.outerOrderNo
//          this.queryOrder()
//        }
      });

      let that = this

      PDD_OPEN_init({
        // code: 'example of sdk code'
        // 对于获取 code 接口或未登录态，可不传：PDD_OPEN_init({}, function () { ... })
      }).then(function () {
        // 初始化已完成
        console.log('PDD_OPEN_init 初始化已完成')
        window.PDD_OPEN_getPati().then(
            function (pati) {
              // 使用 pati
              console.log('PDD_OPEN_getPati 初始化已完成',pati)
              that.axios.defaults.headers.common['X-PDD-Pati'] = pati;
            }).catch(error => console.log(error))
      })
    },
    beforeRouteLeave(to, from, next) {
      // 设置下一个路由的 meta
//      to.meta.keepAlive = false; // 不缓存，即刷新
      consle.log(to, from, next)
      next();
    },
    methods: {
      // 根据url获取店铺
      setStoreId(){
        let storeId = '100000'
        let s = this.$f7route.query.s
        // let hrefs = window.location.href.split("/")
        // if(hrefs.length==5){
        //   //remote
        //   storeId = hrefs[3]
        // }
        if(s){
          storeId = s
        }
        console.log("storeId:",storeId)
        this.axios.defaults.headers.common['storeId'] = storeId;
        this.storeId = storeId
      },
      initLogo(){
        this.$http.post('/store/detail', this.$qs.stringify({
          id: this.storeId,
        })).then(res => {
          console.log(res)
          if (res.status == 200 && res.data.status == 1 && res.data.data.id) {
            this.logoImg = res.data.data.logoImg
          } else {
            this.$f7.toast.create({
              text: res.data.msg,
              position: 'center',
              closeTimeout: 2000,
            }).open()
          }
        }).catch(res => {

        })
      },
      queryOrder() {
        try{
          // 让输入框拾取焦点2021.6.5
          this.$refs.orderInput.blur()
          // 优化iphone的scroll问题2021.6.5
          document.documentElement.scrollTop=0
        }catch(e){
          console.log(e)
        }
        this.$http.post('/order/query', this.$qs.stringify({
          tbOrderNo: this.outerOrderNo,
        })).then(res => {
          console.log(res)
          if (res.status == 200 && res.data.status == 1 && res.data.data.id) {
            console.log("data:",res.data.data)
            this.$f7router.navigate('/order/?p1=123', {
              props: {
                propOrder: res.data.data
              }
            })
          } else {
            this.$f7.toast.create({
              text: res.data.msg,
              position: 'center',
              closeTimeout: 2000,
            }).open()
          }
        }).catch(res => {

        })
      }, clickBackBtn: function (event) {
        event.preventDefault()
        alert(this.$f7router.history.length)
        if (this.$f7router.history.length <= 1) {
          if (hasEvent("backbutton")) {
            var toast = this.$f7.toast.create({
              text: "再按一次退出",
              closeTimeout: 1200
            });
            toast.open();

            unregisterEvent("backbutton");
          }
          setTimeout(() => {
            registerEvent("backbutton", this.clickBackBtn);
          }, 1200);
        } else {
          this.$f7router.back();
        }
      }
    }
  }
</script>
<style>
  input::-webkit-input-placeholder { /* WebKit browsers 适配谷歌 */
    color: #C1CAD3;
  }
</style>
