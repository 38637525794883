var eventDict = {};

export function registerEvent(eventName, listener) {
  if (eventDict[eventName]) {
    return;
  }
  document.addEventListener(eventName, listener, false);
  eventDict[eventName] = listener;
}

export function unregisterEvent(eventName) {
  if (!eventDict[eventName]) {
    return;
  }
  document.removeEventListener(eventName, eventDict[eventName], false);
  delete eventDict[eventName];
}

export function hasEvent(eventName) {
  if (eventDict[eventName]) {
    return true;
  }
  return true;
}
